@import "variables";
.noti-icon .badge {
  left: 23px;
}

.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}

.fcYNFc {
  background-color: #ffffff !important;
}

.task-box {
  border: 1px solid #e1e1e6;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}

.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #2684ff52 !important;
}
.cursor-pointer {
  cursor: pointer;
}

.add-variant-box {
  .details {
    margin-top:10px ;
    background-color: #ffffff;
    padding: 12px;

    .qty-variants {
      span {
        font-size: 14px;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Optional - if you want to remove the margin */
} 

.text-danger {
  font-size: 12px;
}

.purchase-summary {
  justify-content: end;
  margin-top: 15px;

  h4 {
    font-size: 17px;
    font-weight: bold;
  }
  .box {
    border: 1px solid $gray-100;
    border-radius: 10px;
    width: 40%;
    padding-top: 12px;
  }
  .calculate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $gray-100;
    padding: 10px 0;

    &:last-child {
      border-bottom: none;
    }
  }
}

